import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { isShowingBorrowerAvIdGuardedRoutes } = toRefs(useAuthStore());

  if (!isShowingBorrowerAvIdGuardedRoutes.value) {
    return navigateTo({ path: '/' });
  }
});
